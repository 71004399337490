import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index'
import { DateTime } from 'luxon'
import { dbDataHandle } from './function/index'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    timer: '',
    weather: [],
    pop: '',
    upDateTime: '',
    lastCurrentData: [],
    EdayData: [],
    EhourData: [],
    EmonData: [],
    InterData: [],
    YearInterData: [],
    MonInterData: []
  },
  getters: {
  },
  mutations: {
    GETLASTDATA (state, payload) {
      state.lastCurrentData = payload.data
    },
    GETDAYDATA (state, payload) {
      state.EdayData = payload.data
    },
    GETMONDATA (state, payload) {
      state.EmonData = payload.data
    },
    GETHOURDATA (state, payload) {
      state.EhourData = payload.data
    },
    GETINTER_DAYDATA (state, payload) {
      state.InterData = payload.data
    },
    GETINTER_MONDATA (state, payload) {
      state.MonInterData = payload.data
    },
    GETINTER_YEARDATA (state, payload) {
      state.YearInterData = payload.data
    },
    GETUPDATE_TIME (state) {
      state.upDateTime = DateTime.local().toFormat('yyyy-MM-dd HH:mm')
    },
    HANDLE_WEATHER (state, payload) {
      state.weather = payload
    },
    HANDEL_POP (state, payload) {
      state.pop = dbDataHandle(payload * 100, 0)
    }
  },
  actions: {
    async getHourData ({ commit }, beginDate) {
      const todayDate = beginDate !== undefined ? beginDate : DateTime.local().toFormat('yyyy-MM-dd')
      const token = VueCookies.get('Authorization')
      const hourData = await axios.post('/api/ems/shilin/hourData',
        {
          todayDate
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      commit('GETHOURDATA', hourData)
    },
    async getdayData ({ commit }, beginDate) {
      const token = VueCookies.get('Authorization')
      const queryMon = beginDate !== undefined ? beginDate : DateTime.local().toFormat('yyyy-MM')
      const dayData = await axios.post('/api/ems/shilin/dayData',
        {
          queryMon
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      commit('GETDAYDATA', dayData)
    },
    async getMonData ({ commit }, beginDate) {
      const token = VueCookies.get('Authorization')
      const queryYear = beginDate !== undefined ? beginDate : DateTime.local().toFormat('yyyy-MM')
      const monData = await axios.post('/api/ems/shilin/monData',
        {
          queryYear
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      commit('GETMONDATA', monData)
    },
    async getDayInterval ({ commit }, date) {
      const token = VueCookies.get('Authorization')
      const InterData = await axios.post('/api/ems/shilin/dayInterval',
        {
          startDate: date.beginDate,
          endDate: date.endDate
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      // console.log(InterData)
      commit('GETINTER_DAYDATA', InterData)
    },
    async getMonInterval ({ commit }, date) {
      const token = VueCookies.get('Authorization')
      const MonInterData = await axios.post('/api/ems/shilin/monInterval',
        {
          startMon: date.beginDate,
          endMon: date.endDate
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      // console.log(InterData)
      commit('GETINTER_MONDATA', MonInterData)
    },
    async getYearInterval ({ commit }, date) {
      if (date.beginDate === date.endDate) {
        date.endDate = String(parseInt(date.endDate + 1))
      }
      const token = VueCookies.get('Authorization')
      const YearInterData = await axios.post('/api/ems/shilin/yearInterval',
        {
          yearStart: date.beginDate,
          yearEnd: date.endDate
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      // console.log(InterData)
      commit('GETINTER_YEARDATA', YearInterData)
    },
    async getLastMerged ({ commit }) {
      const token = VueCookies.get('Authorization')
      const lastMerged = await axios.post('/api/ems/shilin/lastMerged',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      commit('GETLASTDATA', lastMerged)
    },
    async loginFunction ({ commit }, member) {
      const login = await axios.post('/api/ems/auth/login',
        {
          account: member.account,
          password: member.password
        })
      if (login.data.success) {
        sessionStorage.setItem('login', true)
        Vue.swal('登入成功')
        router.push({ path: '/index' }).catch(err => console.log('router push has error', err))
      } else {
        Vue.swal('帳號或密碼錯誤')
      }
    },
    weatherAPI ({ commit }) {
      // const params = [
      //   'id=1668341',
      //   'appid=edac167ebeb22eb6b0d28ee0d0509287',
      //   'lang=zh_tw'
      // ].join('&')
      axios.get('https://api.openweathermap.org/data/2.5/forecast/daily?lat=25.0478&lon=121.5319&appid=618aaaa4fffcb24d5194539b1fb8acb1&lang=zh_tw').then(({ data }) => {
        const temp = dbDataHandle(data.list[0].temp.day / 10, 1)
        const icon = data.list[0].weather[0].icon
        const description = data.list[0].weather[0].description
        const humidity = data.list[0].humidity
        const weatherIconUrl = `https://openweathermap.org/img/wn/${icon}@2x.png`
        commit('HANDLE_WEATHER', [temp, weatherIconUrl, description, humidity])
        commit('HANDEL_POP', data.list[0].pop)
      })
    }
  },
  modules: {
  }
})
